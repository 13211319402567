import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {getAuthIsAuthenticated, StoreState} from '../store';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    public store: Store<StoreState>,
    public router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {

    return this.store.select(getAuthIsAuthenticated)
      .pipe(
        map(a => {
          if (a) {
            return true;
          } else {
            this.router.navigate(['auth', 'login'], { queryParams: { next: state.url } });
            return false;
          }
        })
      );
  }
}
