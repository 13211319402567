import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {empty as observableEmpty, of as observableOf} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import * as fromLangActions from './actions';
import {AvailableLangs, LangDefault} from './reducer';
import {LangStorageService} from '../../services';

@Injectable()
export class LangEffects {
  constructor(
    public actions$: Actions,
    public langStorageService: LangStorageService,
    public translate: TranslateService
  ) {
  }

  @Effect()
  changeLang$ = this.actions$
    .pipe(
      ofType(fromLangActions.LangActionTypes.CHANGE_LANG),
      map((action: fromLangActions.ChangeLang) => action.payload),
      switchMap(payload => {
        if (payload !== LangDefault) {
          if (!AvailableLangs.find(a => a === payload)) {
            return observableOf(new fromLangActions.ChangeLang(payload));
          }
        }
        this.langStorageService.setValue(payload);
        this.translate.use(payload);
        return observableEmpty();
      })
    );
}
