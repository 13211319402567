import {Observable} from 'rxjs';
import {ApiDataService} from './api.data.service';
import {AbstractModel} from '../../models';

export abstract class ApiListDataService<L extends AbstractModel, T extends AbstractModel> extends ApiDataService<T> {

  getItems = (): Observable<L[]> => {
    return this.get<L[]>('');
  }

  getItemsParams = (params: string): Observable<L[]> => {
    return this.get<L[]>(params);
  }

  createItemParams = (params: string, data: any): Observable<T> => {
    return this.post<T>(params, JSON.stringify(data));
  }

  saveFile = (): Observable<L[]> => {
    return this.save<L[]>('');
  }
}
