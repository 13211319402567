export interface ViolationRegisterModel {
  totalCount: number;
  data: {
    code: string;
    dateFrom?: null | Date;
    dateTo?: null | Date;
    icon: string;
    id: number;
    name: string;
    violationSphere?: ViolationSphere;
    violationGroup?: ViolationGroup;
  }[];
}

export interface ViolationSphere {
  code: string | null;
  id: number;
  name: string;
}

export interface ViolationGroup {
  code: string | null;
  dateFrom?: null | Date;
  dateTo?: null | Date;
  id: number;
  name: string;
}
