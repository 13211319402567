import {Action} from '@ngrx/store';

export enum LangActionTypes {
  CHANGE_LANG = '[Lang] ChangeLang',
}

export class ChangeLang implements Action {
  readonly type = LangActionTypes.CHANGE_LANG;

  constructor(public payload: string) {
  }
}

export type LangActions = ChangeLang;
