import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {Store} from '@ngrx/store';
import {CheckAuth, StoreState, TokenStorageService} from './core';
import {TranslateService} from '@ngx-translate/core';
import {LangDefault} from 'core/store';
import {LangStorageService} from 'core/services';
import {Subject} from "rxjs";

@Component({
  selector: 'dc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LangStorageService]
})
export class AppComponent {
  mouseEntered = new Subject<void>();

  constructor(
    public tokenStorageService: TokenStorageService,
    public store: Store<StoreState>,
    public translate: TranslateService,
    public langStorageService: LangStorageService
  ) {
    const token = this.tokenStorageService.getValue();
    if (token) {
      this.store.dispatch(new CheckAuth());
    }
    this.translate.setDefaultLang(LangDefault);
  }
}
