import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserModel } from '../../models';
import { ApiListDataService } from './api.list-data.service';
import { UserTasksModel } from 'core/models/user-tasks.model';

@Injectable({
    providedIn: 'root',
})
export class ApiUserService extends ApiListDataService<UserModel, UserModel> {
    constructor(http: HttpClient) {
        super(http, '/User/');
    }

    public userInfo = (): Observable<UserModel> => {
        return this.get('UserInfo');
    };
}

@Injectable({
    providedIn: 'root',
})
export class ApiUserWithPasswordService extends ApiListDataService<UserModel, UserModel> {
    constructor(http: HttpClient) {
        super(http, '/User?withoutPassword=true');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiUserWithOutPassword extends ApiListDataService<UserModel, UserModel> {
    constructor(http: HttpClient) {
        super(http, '/User');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiUserExportAppTaskByUser extends ApiListDataService<any, any> {
    constructor(http: HttpClient) {
        super(http, '/User/ExportAppTaskByUser');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiUserExport extends ApiListDataService<any, any> {
    constructor(http: HttpClient) {
        super(http, '/User/Export');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiAppTaskByUser extends ApiListDataService<UserTasksModel, UserTasksModel> {
    constructor(http: HttpClient) {
        super(http, '/User/AppTaskByUser');
    }
}
@Injectable({
    providedIn: 'root',
})
export class ApiAppTaskForUser extends ApiListDataService<UserTasksModel, UserTasksModel> {
    constructor(http: HttpClient) {
        super(http, '/User/AppTaskForUser');
    }
}
