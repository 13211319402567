import {AbstractModel} from './abstract.model';

export interface AbstractServiceModel extends AbstractModel {
    Type: {
        code?: string,
        name?: string,
        id: number
    };
    Cost: number;
    currency: {
        code: string,
        name: string,
        id: number
    };
    Duration: number;
    Code: string;
    Name: string;
    NameEn: string;
}
