import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiListDataService} from './api.list-data.service';
import {ClientDocumentModel} from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ApiClientDocument extends ApiListDataService<ClientDocumentModel, ClientDocumentModel> {

  constructor(http: HttpClient) {
    super(http, '/ClientDocument/');
  }
}
