import {AbstractModel} from './abstract.model';

export interface TaskDistrTaskByServiceModel extends AbstractModel {
  appTaskCompleted: string;
  appTaskCount: null | number;
  userId: string;
  userName: string;
  invalid?: boolean;
  selected?: boolean;
  serviceCount?: number;
  touched?: boolean;
}
