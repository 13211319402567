import { Observable } from 'rxjs';

export * from './abstract.model';
export * from './abstract-dict.model';
export * from './token.model';
export * from './user.model';
export * from './service-type.model';
export * from './service.model';
export * from './package.models';
export * from './abstract-service.model';
export * from './project-item-organization.model';
export * from './client.model';
export * from './client-communication-model';
export * from './client-document.model';
export * from './client-register.model';
export * from './currency.model';
export * from './dashboard.service.model';
export * from './dictionaries.model';
export * from './organization.model';
export * from './organizations-by-role.model';
export * from './package-service.model';
export * from './project-item.model';
export * from './task.model';
export * from './project.service.model';
export * from './package.models';
export * from './chart.model';
export * from './dashboard.model';
export * from './project-item-organization.model';
export * from './appointment.model';
export * from './appointment-patch.model';
export * from './appointment-filter.model';
export * from './organization-by-role.model';
export * from './filtered-organization.model';
export * from './user-by-role-and-org.model';
export * from './pagination.model';
export * from './package-service-by-project-id.model';
export * from './service-single.model';
export * from './task-distr-task-by-service.model';
export * from './client-card.model';
export * from './client-filter-lookup';
export * from './task-distr-task-by-package.model';
export * from './distr-task-by-project.model';
export * from './region-autocomplete.model';
export * from './violation.model';

export interface MultipleChipsAutocompleteInterface {
    placeholder: string;
    formControlName: string;
    serverField: string;
    responseData: object;
    defaultParam?: any | number;
    componentMode: 'single' | 'multiple-chips';
    requestedData: (payload?: any) => Observable<any>;
    hiddenSelect: string;
    fieldArr: string;
    multiChipList: string[];
    additionalFilds?: string;
    isDisabled: boolean;
    objValue: any;
    dataType?: 'object' | 'string' | 'number';
}

export interface DateObj {
    dateStart: Date;
    dateEnd: Date;
}
