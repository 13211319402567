import {AbstractModel} from './abstract.model';

export interface ClientFilterLookup extends AbstractModel {
  organizationList:
    {
      code: string;
      name: string;
      id: number;
    }[];
  consultantList: {
    code: string;
    name: string;
    id: number;
  }[];
}
