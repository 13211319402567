export interface ProjectItemOrganizationModel {
  projectItem: {
    code?: string;
    name?: string;
    id?: number;
    count?: number
  };
  organization?: {
    code?: string;
    name?: string;
    id: number;
  };
  price?: number;
  count?: number;
  currency?: {
    code?: string;
    name?: string;
    id: number
  };
  service?: {
    type: {
      code: string;
      name: string;
      id: number
    };
    cost?: number;
    currency?: {
      code: string;
      name: string;
      id: number
    };
    duration?: number;
    code?: string;
    name?: string;
    nameEn?: string;
    id?: number
  };
  package?: {
    code: string;
    name: string;
    nameEn: string;
    id: number
  };
  packageService?: [
    {
      package: {
        code: string;
        name: string;
        id: number
      };
      service: {
        code: string;
        name: string;
        id: number
      };
      count?: number;
      order: number;
      period: number;
      previousService: {
        code: string;
        name: string;
        id: number
      };
      id: number
    }
    ];
  id?: number;
}
