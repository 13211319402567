import {AbstractModel} from './abstract.model';

export interface TaskModel extends AbstractModel {
  project: {
    code: string;
    name: string;
    id: number;
  };
  packages: [
    {
      service: {
        code: string;
        name: string;
        id: number;
      };
      id: number;
    }
    ];
  name: string;
  id: number;
}
