export interface ServiceSingleModel {
  code: string;
  cost: number;
  currency: {
    code: string; name: string; id: number;
  };
  duration: number;
  id?: number;
  name: string;
  nameEn: string;
  type: {
    code?: string,
    name?: string;
    id: number;
  };
}
