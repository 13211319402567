import {Injectable} from '@angular/core';
import {Router} from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class RoleStorageService {

  public static roleKey = 'roles';
  constructor(
    public router: Router,
  ) { }

  public getValue() {
    if (localStorage.getItem('role')) {
      localStorage.clear();
      this.router.navigate(['auth', 'login']);
    } else {
      return localStorage.getItem(RoleStorageService.roleKey)?.split(',').map(role => +role);
    }
  }

  public setValue(value: string) {
    localStorage[RoleStorageService.roleKey] = value;
  }

  public removeValue() {
    localStorage.removeItem(RoleStorageService.roleKey);
  }
}
