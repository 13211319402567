import { Injectable } from '@angular/core';
import { ApiListDataService } from './api.list-data.service';
import { HttpClient } from '@angular/common/http';
import { AbstractModel } from '../../models';
import { StandartObject } from 'core/models/standart-object-model';
import { DictionaryNameModel } from 'core/models/dictionary-name.model';

@Injectable({
    providedIn: 'root',
})
export class ApiDictionariesService extends ApiListDataService<AbstractModel, AbstractModel> {
    constructor(http: HttpClient) {
        super(http, '/Dictionaries/ProjectState/');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiClientProphylaxisType extends ApiListDataService<AbstractModel, AbstractModel> {
    constructor(http: HttpClient) {
        super(http, '/Dictionaries/byCode/ProphylaxisType');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiClientHepatitisBVaccine extends ApiListDataService<AbstractModel, AbstractModel> {
    constructor(http: HttpClient) {
        super(http, '/Dictionaries/byCode/HepatitisBVaccine');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiClientChemoprophylaxisMedicine extends ApiListDataService<
    AbstractModel,
    AbstractModel
> {
    constructor(http: HttpClient) {
        super(http, '/Dictionaries/byCode/ChemoprophylaxisMedicine');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiProject extends ApiListDataService<AbstractModel, AbstractModel> {
    constructor(http: HttpClient) {
        super(http, '/Project');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiReasonExitingPac extends ApiListDataService<AbstractModel, AbstractModel> {
    constructor(http: HttpClient) {
        super(http, '/Dictionaries/byCode/ReasonExitingPackage');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiReasonExitingProject extends ApiListDataService<AbstractModel, AbstractModel> {
    constructor(http: HttpClient) {
        super(http, '/Dictionaries/byCode/ReasonExitingProject');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiPackage extends ApiListDataService<AbstractModel, AbstractModel> {
    constructor(http: HttpClient) {
        super(http, '/Package/');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiCommitmentLevel extends ApiListDataService<AbstractModel, AbstractModel> {
    constructor(http: HttpClient) {
        super(http, '/Dictionaries/byCode/CommitmentLevel');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiRowARTs extends ApiListDataService<AbstractModel, AbstractModel> {
    constructor(http: HttpClient) {
        super(http, '/Dictionaries/byCode/RowART');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiSMTInterruptionReasons extends ApiListDataService<AbstractModel, AbstractModel> {
    constructor(http: HttpClient) {
        super(http, '/Dictionaries/byCode/SMTInterruptionReason');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiSMTMedicationSchedules extends ApiListDataService<AbstractModel, AbstractModel> {
    constructor(http: HttpClient) {
        super(http, '/Dictionaries/byCode/SMTMedicationSchedule');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiSMTPrescriptions extends ApiListDataService<AbstractModel, AbstractModel> {
    constructor(http: HttpClient) {
        super(http, '/Dictionaries/byCode/SMTPrescription');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiSMTOrganizationTypes extends ApiListDataService<AbstractModel, AbstractModel> {
    constructor(http: HttpClient) {
        super(http, '/Dictionaries/byCode/SMTOrganizationType');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiARTShema extends ApiListDataService<AbstractModel, AbstractModel> {
    constructor(http: HttpClient) {
        super(http, '/Dictionaries/byCode/ARTShema');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiARTChangeReason extends ApiListDataService<AbstractModel, AbstractModel> {
    constructor(http: HttpClient) {
        super(http, '/Dictionaries/ARTChangeReasonByTreatmentARTOperType/');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiRepresentationReason extends ApiListDataService<AbstractModel, AbstractModel> {
    constructor(http: HttpClient) {
        super(http, '/Dictionaries/byCode/RepresentationReason');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiClientCategoryAppeals extends ApiListDataService<AbstractModel, AbstractModel> {
    constructor(http: HttpClient) {
        super(http, '/Dictionaries/byCode/ClientCategoryAppeal');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiRelationDegree extends ApiListDataService<any, any> {
    constructor(http: HttpClient) {
        super(http, '/Dictionaries/byCode/RelationDegree');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiSpecialty extends ApiListDataService<StandartObject, StandartObject> {
    constructor(http: HttpClient) {
        super(http, '/Dictionaries/byCode/Specialty');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiGeneralDailyAppointmentReportPeriod extends ApiListDataService<any, any> {
    constructor(http: HttpClient) {
        super(http, '/Dictionaries/GeneralDailyAppointmentReportPeriod/webregister');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiDictionaryNameList extends ApiListDataService<
    DictionaryNameModel,
    DictionaryNameModel
> {
    constructor(http: HttpClient) {
        super(http, '/Dictionaries/Name/webregister');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiDictionaryName extends ApiListDataService<any, any> {
    constructor(http: HttpClient) {
        super(http, '/Dictionaries/Name/');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiDictionarySpecialRights extends ApiListDataService<any, any> {
    constructor(http: HttpClient) {
        super(http, '/Dictionaries/SpecialRightsByRolesLookup/');
    }
}
