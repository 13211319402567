import {ActionReducerMap, createFeatureSelector} from '@ngrx/store';
import {authReducer, AuthState} from '../auth/reducer';
import {AuthEffects} from '../auth/effects';
import * as fromLangReducer from '../lang/reducer';
import {LangEffects} from '../lang/effects';

export interface StoreState {
  auth: AuthState;
  lang: fromLangReducer.LangState;
}

export const reducers: ActionReducerMap<StoreState> = {
  auth: authReducer,
  lang: fromLangReducer.reducer
};

export const getAuthState = createFeatureSelector<StoreState, AuthState>('auth');
export const getLangState = createFeatureSelector<fromLangReducer.LangState>('lang');

export const effects: any[] = [
  AuthEffects,
  LangEffects
];
