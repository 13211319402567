import {AbstractModel} from './abstract.model';

export interface ClientCardModel extends AbstractModel {
  lastName: string;
  firstName: string;
  middleName: string;
  lightClient: boolean;
  casePP: string;
  mainPhone: string;
  birthDay: Date;
  gender: 1;
  country: {
    code: string;
    name: string;
    id: number
  };
  maritalStatus: 1;
  rhesusFactor: 1;
  educationLevel: 1;
  identifier: string;
  childrenCount: number;
  appUser: {
    code: string;
    name: string;
    id: number
  };
  clientCommunications: [
    {
      communicationType: 1;
      value: string;
      note: string;
      id: number;
    }
  ];
  clientDocuments: [
    {
      identDocType: 1;
      identDocSeries: string;
      identDocNumber: string;
      identDocIssue: string;
      identDocDate: Date;
      id: number
    }
  ];
  clientCategories: number[];
  clientTypes: number[];
  clientUsers: [
    {
      userName: string;
      projectName: string;
      organizationName: string
    }
  ];
  clientPenitentiaryRegisterDto: [
    {
      id: number;
      dateFrom: Date;
      dateTo: Date;
      regionName: string;
      organizationName: string;
    }
  ];
}
