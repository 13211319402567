export interface DashboardServiceModel {
  consultantCount: number;
  clientCount: number;
  appointmentCount: number;
  appointmentTaskCount: number;
  subRecipientCount: number;
  projectCount: number;
  projectList: [
    {
      code: string;
      name: string;
      id: number;
    }
    ];
  organizationList: [
    {
      code: string;
      name: string;
      id: number;
    }
    ];
  appointmentByWeek: [
    {
      key: string;
      value: number;
    }
    ];
  appointmentByMonth: [
    {
      key: string;
      value: number;
    }
    ];
  appointmentTaskByWeek: [
    {
      key: string;
      value: number;
    }
    ];
  appointmentTaskMonth: [
    {
      key: string;
      value: number;
    }
    ];
  appointmentNumByHour: [
    {
      key: string;
      value: number;
    }
    ];
}
