import {Injectable} from '@angular/core';

@Injectable(

)
export class MaskService {
  mask = '+00 (000) 000-0000';
  date = '00.00.0000';
  time = '00:00';
  phoneMask = '(000) 000-0000';
}
