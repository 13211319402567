import {Action} from '@ngrx/store';

import {UserModel} from '../../models';

export enum AuthActionTypes {
    CHECK_AUTH = '[Auth] CheckAuth',
    CHECK_AUTH_ERROR = '[Auth] CheckAuthError',
    // SIGNUP_USER = '[Auth] SignupUser',
    LOGIN_USER = '[Auth] LoginUser',
    LOGIN_USER_SUCCESS = '[Auth] LoginUserSuccess',
    LOGIN_USER_ERROR = '[Auth] LoginUserError',
    LOGIN_USER_ERROR_CLEAR = '[Auth] LoginUserErrorClear',
    LOGOUT_USER = '[Auth] LogoutUser',
}

export interface LoginUserPayload {
    email: string;
    password: string;
}

export class CheckAuth implements Action {
    readonly type = AuthActionTypes.CHECK_AUTH;
}

export class CheckAuthError implements Action {
    readonly type = AuthActionTypes.CHECK_AUTH_ERROR;

    constructor(public payload: Error) { }
}

export class LoginUser implements Action {
    readonly type = AuthActionTypes.LOGIN_USER;
    constructor(public payload: LoginUserPayload) { }
}

export class LoginUserSuccess implements Action {
    readonly type = AuthActionTypes.LOGIN_USER_SUCCESS;

    constructor(public payload: UserModel) { }
}

export class LoginUserError implements Action {
    readonly type = AuthActionTypes.LOGIN_USER_ERROR;

    constructor(public payload: any) { } // ??
}

export class LoginUserErrorClear implements Action {
    readonly type = AuthActionTypes.LOGIN_USER_ERROR_CLEAR;
}

export class LogoutUser implements Action {
    readonly type = AuthActionTypes.LOGOUT_USER;
}

export type AuthActions = CheckAuth | CheckAuthError | LoginUser | LoginUserSuccess | LoginUserError | LoginUserErrorClear | LogoutUser;
