import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiListDataService} from './api.list-data.service';
import {ServiceModel} from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceDeleteMultipleService extends ApiListDataService<ServiceModel, ServiceModel> {

  constructor(http: HttpClient) {
    super(http, '/Service/DeleteMultiple/');
  }
}
