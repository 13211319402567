import {AbstractModel} from './abstract.model';

export interface TaskDistrTaskByPackageModel extends AbstractModel {
  userId: number;
  userName: string;
  distributionStatus: number;
  distributedCount: number;
  servicesCount: number;
  touched?: boolean;
}
