import {Injectable} from '@angular/core';

@Injectable()
export class GetSafeService {
  constructor() {
  }

  public getSafe(fn, defaultVal) {
    try {
      return fn();
    } catch (e) {
      return defaultVal;
    }
  }

}
