export interface CountryModel {
  data: {
    code: string | null,
    id: number,
    isSelected: boolean | null
    name: string
  }[],
  totalCount: number,
}

export interface RegionAutocompleteModel {
  data: {
    code?: string | null;
    id: number;
    koatuuCode: string;
    country: CountryModel['data'][0];
    name?: string;
  }[],
  totalCount: number,
}

export interface DistrictAutocompleteModel {
  data: {
    id: number,
    koatuuCode: string,
    name: string,
    region: RegionAutocompleteModel['data'][0],
    country: CountryModel['data'][0],
  }[],
  totalCount: number,
}

export interface SettlementAutocompleteModel {
  data: {
    id: number,
    koatuuCode: string,
    name: string,
    region: RegionAutocompleteModel['data'][0],
    country: CountryModel['data'][0],
    district: DistrictAutocompleteModel['data'][0],
  }[],
  totalCount: number,
}

export interface SettlementDistrictAutocompleteModel {
  data: {
    id: number,
    koatuuCode: string,
    name: string,
    // region: RegionAutocompleteModel['data'][0],
    // country: CountryModel['data'][0],
    // district: DistrictAutocompleteModel['data'][0],
    settlement: SettlementAutocompleteModel['data'][0],
  }[],
  totalCount: number,
}
