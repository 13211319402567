import {AbstractModel} from './abstract.model';

export interface ClientRegisterModel extends AbstractModel {
  organizationIds?: [];
  consultantIds?: [];
  lastName: string;
  firstName: string;
  middleName: string;
  casePP: string;
  birthdayFrom: Date;
  birthdayTo: Date;
  gender: number;
  clientType: number;
  clientCategorys: [];
  page: number;
  pageSize: number;
  orderBy: string;
  reverse: boolean;
  birthDay?: Date;
  clientTypeDateFrom: Date;
}
