export interface ProjectServiceModel {
  state: {
    code?: string,
    name?: string,
    id: number
  };
  recepient: {
    code?: string,
    name?: string,
    id: number
  };
  donor?: {
    code?: string,
    name?: string,
    id?: number
  };
  description: string;
  contract: {
    id: number
  };
  cost: number;
  currency: {
    code?: string,
    name?: string,
    id: number
  };
  dateFrom: string;
  dateTo: string;
  code: string;
  name: string;
  nameEn: string;
  id?: number;
}
