<div class="navbar-container">
  <div class="navbar">
    <div class="logo" routerLink="/">
      <img src="assets/images/logo.png">
    </div>
  </div>
  <div class="consent container">
    <h2 class="consent__header">ЗГОДА НА ОБРОБКУ ПЕРСОНАЛЬНИХ ДАНИХ</h2>
    <h3 class="consent__header">(надалі - Згода)</h3>
    <p class="consent__text">
      Відповідно до пунктів 1 і 2 частини другої статті 8, частини другої статті 12 Закону України «Про захист персональних даних» (надалі – Закон),
      <b> БЛАГОДІЙНА ОРГАНІЗАЦІЯ «ВСЕУКРАЇНСЬКА МЕРЕЖА ЛЮДЕЙ, ЯКІ ЖИВУТЬ З ВІЛ/СНІД»</b>,
      код ЄДРПОУ - 21721459; місцезнаходження: 04080, місто Київ, вулиця Межигірська,
      будинок 87-А, літера В; номер контактного телефону:+38 (044) 467 75 67,
      (надалі – БО «100 ВІДСОТКІВ ЖИТТЯ») повідомляє про володільця, розпорядника, місцезнаходження,
      склад персональних даних та мету збору персональних даних, що обробляються за допомогою програмних засобів у комп'ютерній програмі
      «Інформаційно-телекомунікаційна система «Моніторинг надання соціальних послуг в Україні: DATACHECK UKRAINE» (скорочено та надалі по тексту - ІТС «DATACHECK UKRAINE»),
      порядок використання та поширення персональних даних, порядок доступу до персональних даних третіх осіб та права суб’єкта персональних даних.
    </p>
    <ol class="consent__main-list">
      <li class="consent__item">
        <b>Я, як суб’єкт персональних даних, повідомлений (а), що:</b>
        <ol class="consent__secondary-list">
          <li class="consent__item">БО «100 ВІДСОТКІВ ЖИТТЯ» є правовласником ІТС «DATACHECK UKRAINE», якому належать майнові права, авторські права на ІТС «DATACHECK UKRAINE» (Свідоцтво про реєстрацію авторського права на твір No 102310 від 03 лютого 2021 року), а також є володільцем бази даних ІТС «DATACHECK UKRAINE» та розпорядником персональних даних, що обробляються за допомогою ІТС «DATACHECK UKRAINE».</li>
          <li class="consent__item">До складу персональних даних, що обробляються за допомогою ІТС «DATACHECK UKRAINE», включаються персональні дані щодо мене та/або іншої особи в інтересах якої я дію, а саме: прізвище, ім’я, по-батькові, дата народження, стать, номер телефону, електронна пошта, місце проживання та/або місце реєстрації, а також інші дані, необхідність обробки яких визначена специфікою діяльності БО «100 ВІДСОТКІВ ЖИТТЯ», в тому числі, але не виключно, відомості про освіту, сімейний стан, кількість дітей (за наявності), інформація щодо зайнятості, наявність встановленої групи інвалідності, види соціальної допомоги, поведінкові особливості (в тому числі інформація про сексуальну орієнтацію та гендерну належність), контактну інформацію найближчого оточення (в тому числі, але не виключно, родичів, друзів, тощо), медичні дані та дані, які стосуються стану здоров’я (в тому числі, але не виключно, щодо отримання медичних послуг, облік у медичних закладах, стан здоров’я та захворювання тощо), які необхідні для надання послуг БО “100 ВІДСОТКІВ ЖИТТЯ” та/ або її партнерами, тощо.</li>
          <li class="consent__item">Під обробкою персональних даних розуміється будь-яка дія або сукупність дій, здійснюваних повністю або частково в ІТС «DATACHECK UKRAINE», які пов’язані із збиранням, реєстрацією, накопиченням, зберіганням, адаптуванням, зміною, поновленням, використанням і поширення (розповсюдженням, реалізацією, передачею), знеособлення, знищення персональних даних щодо мене, як суб’єкта який надав свої персональні дані, чи персональні дані іншої особи в інтересах якої я дію.</li>
          <li class="consent__item">
            Метою обробки персональних даних є надання БО «100 ВІДСОТКІВ ЖИТТЯ» благодійної допомоги та сприяння законним інтересам бенефіціарів, відповідно до Статуту БО «100 ВІДСОТКІВ ЖИТТЯ», що передбачає:
            <ul>
              <li>ведення персональних даних клієнтів (відповідно до вимог донорів, за фінансової підтримки яких реалізуються благодійні проекти, в рамках яких виконавцями проектів або іншими партнерськими організаціями, надаються бенефіціарам медичні, соціальні, юридичні та інші послуги);</li>
              <li>для оцінки якості надання послуг у сфері охорони здоров’я, соціального захисту та відновлення порушених прав клієнтів;</li>
              <li>відстеження та оцінки змін якості життя клієнтів та стану здоров'я після отримання ними послуг від БО «100 ВІДСОТКІВ ЖИТТЯ» та/або іншими партнерськими організаціями;</li>
              <li>реєстрація випадків порушення прав з метою їх моніторингу та/або відновлення.</li>
            </ul>
          </li>
        </ol>
        <p class="consent__text">Мета обробки персональних даних суб’єктів персональних даних може змінюватися внаслідок зміни умов благодійних проектів, укладених договорів або ділових відносин БО «100 ВІДСОТКІВ ЖИТТЯ», змісту її діяльності, у зв‘язку із зміною законодавства України, тощо.</p>
      </li>
      <li class="consent__item">
        <b>Я, як суб’єкт персональних даних, надаю БО «100 ВІДСОТКІВ ЖИТТЯ»
          добровільну, інформовану та однозначну згоду на обробку в ІТС «DATACHECK UKRAINE»
          моїх персональних даних або персональних даних іншої особи в інтересах якої я дію, а саме на:</b>
        <ol class="consent__secondary-list">
          <li class="consent__item">збір, обробку, накопичення та зберігання персональних даних, які включають в себе дані, передбачені в п. 1.2. даної Згоди (ст.12, 13 Закону);</li>
          <li class="consent__item">використання персональних даних, що передбачає дії володільця щодо обробки цих даних, в тому числі використання персональних даних працівниками володільця відповідно до їх професійних чи службових або трудових обов’язків, дії щодо їх захисту, а також дії щодо надання часткового або повного права обробки персональних даних іншим суб’єктам відносин, пов’язаних із персональними даними (стаття 10 Закону);</li>
          <li class="consent__item">поширення персональних даних, що передбачає дії володільця бази персональних даних щодо передачі відомостей про мене - фізичну особу або відомості про іншу особу в інтересах якої я дію з ІТС «DATACHECK UKRAINE» (ст. 14 Закону). В тому числі, але не виключно, надаю згоду на передачу вказаних даних іншім третім особам (фізичним та юридичним) з метою здійснення необхідних заходів для відновлення моїх порушених прав чи порушених прав особи, в інтересах якої я дію та/або надання інших необхідних послуг, передбачених проектами БО «100 ВІДСОТКІВ ЖИТТЯ» та/або її партнерів;</li>
          <li class="consent__item">доступ до персональних даних третіх осіб, що визначає дії володільця бази персональних даних у разі отримання запиту від третьої особи, в порядку передбаченому чинним законодавством України, щодо доступу до персональних даних, у тому числі порядок доступу суб’єкта персональних даних до відомостей про себе (ст. 16 Закону);</li>
          <li class="consent__item">розуміючи наявність у мене права на таємницю про стан здоров'я відповідно до чинного законодавства України, я надаю добровільну, інформовану та однозначну згоду на обробку моїх та особи, в інтересах якої я дію, медичних даних та даних, які стосуються стану здоров’я (в тому числі, але не виключно, відомості про ВІЛ-статус, психічні та інші захворювання, наявність встановленої групи інвалідності, отримання медичних послуг, облік у медичних закладах, тощо), поведінкових особливостей (в тому числі інформація про сексуальну орієнтацію та гендерну належність), тощо.</li>
        </ol>
      </li>
      <li class="consent__item">
        <b>Цим я підтверджую, що належним чином повідомлений(а), що:</b>
        <ol class="consent__secondary-list">
          <li class="consent__item">БО «100 ВІДСОТКІВ ЖИТТЯ» прийняла на себе зобов’язання щодо захисту персональних даних та вживає технічних і організаційних заходів щодо захисту отриманих персональних даних.</li>
          <li class="consent__item">Обробка персональних даних проводитиметься працівниками БО «100 ВІДСОТКІВ ЖИТТЯ» та/або працівниками партнерських організацій, які надали письмові зобов’язання про нерозголошення персональних даних інших осіб, що стали відомі у зв’язку з виконанням посадових обов’язків.</li>
          <li class="consent__item">Згідно зі статтею 14 Закону, БО «100 ВІДСОТКІВ ЖИТТЯ» має право на поширення, передання персональних даних особи без повідомлення їй про це у випадках: 1) якщо передача персональних даних прямо передбачена законодавством України, і лише в інтересах національної безпеки, економічного добробуту та прав людини; 2) отримання запиту від органів державної влади і місцевого самоврядування, що діють у межах повноважень, наданих законодавством України; 3) подання звітності (у знеособленому вигляді) щодо здійсненої роботи донорській чи іншій організації, за фінансової підтримки яких впроваджується реалізація проектів БО «100 ВІДСОТКІВ ЖИТТЯ», представникам донора, аудиторам БО «100 ВІДСОТКІВ ЖИТТЯ», у разі, якщо відповідна експертиза (оцінка) інформації проводиться вказаними особами).</li>
          <li class="consent__item">В інших випадках, ніж зазначених у пункті 2 та 3.3 даної Згоди, доступ до моїх персональних даних надається третім особам лише за моєї письмової згоди по кожному запиту окремо.</li>
          <li class="consent__item">Строки зберігання персональних даних визначаються згідно зі строками зберігання відповідних документів, встановленими законодавством України та вимогами донорів, за фінансової підтримки яких впроваджується реалізація проектів БО «100 ВІДСОТКІВ ЖИТТЯ», після чого персональні дані підлягають знищенню у визначеному законодавством порядку.</li>
        </ol>
      </li>
      <li class="consent__item">
        Відповідно до статті 8 Закону України «Про захист персональних даних» мені роз’яснено мої права, в тому числі:
        <ol class="consent__secondary-list">
          <li class="consent__item">знати про джерела збирання, місцезнаходження своїх персональних даних, мету їх обробки, місцезнаходження або місце проживання (перебування) володільця чи розпорядника персональних даних або дати відповідне доручення щодо отримання цієї інформації уповноваженим ним особам, крім випадків, встановлених законом;</li>
          <li class="consent__item">отримувати інформацію про умови надання доступу до персональних даних, зокрема інформацію про третіх осіб, яким передаються його персональні дані;</li>
          <li class="consent__item">на доступ до своїх персональних даних;</li>
          <li class="consent__item">отримувати не пізніш як за тридцять календарних днів з дня надходження запиту, крім
            випадків, передбачених законом, відповідь про те, чи обробляються його персональні дані, а також отримувати зміст таких персональних даних;</li>
          <li class="consent__item">пред’являти вмотивовану вимогу володільцю персональних даних із запереченням проти обробки своїх персональних даних;</li>
          <li class="consent__item">пред’являти вмотивовану письмову вимогу щодо зміни або знищення своїх персональних даних будь-яким володільцем та розпорядником персональних даних, якщо ці дані обробляються незаконно чи є недостовірними;</li>
          <li class="consent__item">на захист своїх персональних даних від незаконної обробки та випадкової втрати, знищення, пошкодження у зв’язку з умисним приховуванням, ненаданням чи несвоєчасним їх наданням, а також на захист від надання відомостей, що є недостовірними чи ганьблять честь, гідність та ділову репутацію фізичної особи;</li>
          <li class="consent__item">звертатися із скаргами на обробку своїх персональних даних до Уповноваженого Верховної Ради України з прав людини або до суду;</li>
          <li class="consent__item">застосовувати засоби правового захисту в разі порушення законодавства про захист персональних даних;</li>
          <li class="consent__item">вносити застереження стосовно обмеження права на обробку своїх персональних даних під час надання згоди;</li>
          <li class="consent__item">відкликати згоду на обробку персональних даних;</li>
          <li class="consent__item">знати механізм автоматичної обробки персональних даних;</li>
          <li class="consent__item">на захист від автоматизованого рішення, яке має для нього правові наслідки.</li>
        </ol>
      </li>
      <li class="consent__item">
        Контроль за додержанням законодавства про захист персональних даних у межах повноважень,
        передбачених законодавством України, здійснює Уповноважений Верховної Ради України з прав людини.
      </li>
      <li class="consent__item">
        Зміст даної Згоди на обробку персональних даних,
        мета та умови обробки моїх персональних даних мені зрозумілі і я погоджуюсь з ними,
        жодних обмежень за цією Згодою мною не встановлено.
      </li>
      <li class="consent__item">Моментом надання моєї згоди на обробку персональних даних в ІТС «DATACHECK
        UKRAINE» є проставлення на відповідній сторінці/вкладці в ІТС «DATACHECK UKRAINE»
        відмітки про надання дозволу на обробку своїх персональних даних, а саме «Я даю згоду на обробку моїх персональних даних».
        Ця Згода є чинною з моменту її надання та діє до моменту її відкликання,
        що здійснюється шляхом подання мною до БО «100 ВІДСОТКІВ ЖИТТЯ» відповідної заяви в письмовій формі про відкликання згоди на обробку персональних даних.
      </li>
      <li class="consent__item">
        У випадку зміни моїх персональних даних, я погоджуюсь надавати у
        найкоротший термін відповідальній особі БО «100 ВІДСОТКІВ ЖИТТЯ» та/або її партнерським організаціям уточнену
        інформацію та для внесення моїх уточнених особистих даних до ІТС «DATACHECK UKRAINE».
      </li>
    </ol>
  </div>
</div>
