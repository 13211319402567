import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiListDataService} from './api.list-data.service';
import {AbstractModel, AppointmentPatchModel} from '../../models';
import {ApiDataService} from "core/services";

@Injectable({
  providedIn: 'root'
})
export class ApiAppointmentPatchService extends ApiListDataService<AppointmentPatchModel, AppointmentPatchModel> {

  constructor(http: HttpClient) {
    super(http, '/Appointment/');
  }
}
