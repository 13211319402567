import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiListDataService} from './api.list-data.service';

@Injectable({
  providedIn: 'root'
})
export class ApiAppealService extends ApiListDataService<any, any> {

  constructor(http: HttpClient) {
    super(http, '/Appeal/webregister/');
  }
}
@Injectable({
  providedIn: 'root'
})
export class ApiAppealSingleService extends ApiListDataService<any, any> {

  constructor(http: HttpClient) {
    super(http, '/Appeal/');
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiAppealFilesService extends ApiListDataService<any, any> {

  constructor(http: HttpClient) {
    super(http, '/Appeal/Files/');
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiAppealStateDetailsService extends ApiListDataService<any, any> {

  constructor(http: HttpClient) {
    super(http, '/Appeal/StateDetails/');
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiAppealDownload extends ApiListDataService<any, any> {

  constructor(http: HttpClient) {
    super(http, '/Appeal/appealsDataExport/');
  }
}


