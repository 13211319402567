import {Injectable} from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class TokenStorageService {

    public static storageKey = 'accessToken';

    public getValue(): string {
        return localStorage[TokenStorageService.storageKey];
    }

    public setValue(value: string) {
        localStorage[TokenStorageService.storageKey] = value;
    }

    public removeValue() {
        localStorage.removeItem(TokenStorageService.storageKey);
    }
}
