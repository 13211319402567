import {AbstractModel} from "core/models/abstract.model";

export interface DashboardModel extends AbstractModel{
  appointmentByMonth: {
      key: string;
      value: number;
    }[];
  appointmentByWeek: {
      key: string;
      value: number;
    }[];
  appointmentCount: number;
  appointmentNumByHour: {
      key: string;
      value: number;
    }[];
  appointmentTaskByWeek: {
      key: string;
      value: number;
    }[];
  appointmentTaskCount: number;
  appointmentTaskMonth: {
      key: string;
      value: number;
    }[];
  consultantCount: number;
  clientCount: number;
  organizationList: {
      code: string;
      name: string;
      id: number;
      isSelected?: null;
    }[];
  projectCount: number;
  projectList: {
      code: string;
      name: string;
      id: number;
      isSelected?: null;
    }[];
  subRecipientCount: number;
}
