import {ChangeLang, LangActions, LangActionTypes} from '../lang/actions';

export const LangDefault = 'ua';

export const AvailableLangs: string[] = [LangDefault, 'ru'];

export interface LangState {
  lang: string;
}

const initialState: LangState = {
  lang: LangDefault
};

export function reducer(state = initialState, action: LangActions): LangState {
  switch (action.type) {
    case LangActionTypes.CHANGE_LANG:
      const payloadLang = (action as ChangeLang).payload;
      return {
        ...state,
        lang: payloadLang,
      };
    default:
      return state;
  }
}
