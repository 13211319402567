import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiListDataService } from './api.list-data.service';
import { PackageServiceByProjectIdModel } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class ApiPackageServiceByProjectIdService extends ApiListDataService<
    PackageServiceByProjectIdModel,
    PackageServiceByProjectIdModel
> {
    constructor(http: HttpClient) {
        super(http, '/PackageService/ByPackageId');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiPackageServicesService extends ApiListDataService<any, any> {
    constructor(http: HttpClient) {
        super(http, '/Package/PackageService');
    }
}
