export interface ClientModel {
  lastName: string;
  firstName: string;
  middleName: string;
  casePP: string;
  mainPhone: string;
  birthDay: string;
  gender: number;
  country: {
    code: string;
    name: string;
    id: number;
  };
  maritalStatus: {
    code: string;
    name: string;
    id: number;
  };
  rhesusFactor: {
    code: string;
    name: string;
    id: number;
  };
  educationLevel: {
    code: string;
    name: string;
    id: number;
  };
  identifier: string;
  childrenCount: number;
  appUser: {
    code: string;
    name: string;
    id: number;
  };
  id: number;
  partners: object;
}
