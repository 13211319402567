import {AbstractModel} from './abstract.model';

export interface PackageServiceByProjectIdModel extends AbstractModel {
  package: {
    code: string;
    name: string;
    id: number;
  };
  service: {
    name: string;
    duration: number;
    id: number;
  };
  count: number;
  order: number;
  period: number;
  previousService: {
    code: string;
    name: string;
    id: number;
  };
  id: number;
}
