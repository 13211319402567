import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiListDataService} from './api.list-data.service';
import {AbstractDictModel} from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceTypesDeleteMultipleService extends ApiListDataService<AbstractDictModel, AbstractDictModel> {

  constructor(http: HttpClient) {
    super(http, '/ServiceType/DeleteMultiple');
  }
}
