import { AuditModel } from 'core/models/audit.model';

export interface ClientCommunicationModel extends AuditModel {
    client?: {
        code: string;
        name: string;
        id: number;
    };
    communicationType?: number;
    value?: string;
    note?: string;
    id: number;
}
