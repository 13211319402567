import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiListDataService} from './api.list-data.service';

@Injectable({
  providedIn: 'root'
})
export class ApiViolationSphereRegister extends ApiListDataService<any, any> {

  constructor(http: HttpClient) {
    super(http, '/ViolationDictionaries/ViolationSphereRegister');
  }
}


@Injectable({
  providedIn: 'root'
})
export class ApiViolationSphere extends ApiListDataService<any, any> {

  constructor(http: HttpClient) {
    super(http, '/ViolationDictionaries/ViolationSphere/');
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiViolationGroupRegister extends ApiListDataService<any, any> {

  constructor(http: HttpClient) {
    super(http, '/ViolationDictionaries/ViolationGroupRegister/');
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiViolationGroup extends ApiListDataService<any, any> {

  constructor(http: HttpClient) {
    super(http, '/ViolationDictionaries/ViolationGroup/');
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiViolationKindRegister extends ApiListDataService<any, any> {

  constructor(http: HttpClient) {
    super(http, '/ViolationDictionaries/ViolationKindRegister/');
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiViolationKind extends ApiListDataService<any, any> {

  constructor(http: HttpClient) {
    super(http, '/ViolationDictionaries/ViolationKind/');
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiViolationLocationTypeRegister extends ApiListDataService<any, any> {

  constructor(http: HttpClient) {
    super(http, '/ViolationDictionaries/ViolationLocationTypeRegister/');
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiViolationLocationType extends ApiListDataService<any, any> {

  constructor(http: HttpClient) {
    super(http, '/ViolationDictionaries/ViolationLocationType/');
  }
}
