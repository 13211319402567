import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiListDataService} from './api.list-data.service';
import {ClientCommunicationModel} from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ApiClientCommunication extends ApiListDataService<ClientCommunicationModel, ClientCommunicationModel> {

  constructor(http: HttpClient) {
    super(http, '/ClientCommunication/');
  }
}
