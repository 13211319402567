import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiListDataService} from './api.list-data.service';
import {AppointmentModel} from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ApiAppointmentService extends ApiListDataService<AppointmentModel, AppointmentModel> {

  constructor(http: HttpClient) {
    super(http, '/Appointment/');
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiAppointmentDeleteAppointmentAndServices extends ApiListDataService<any, any> {

  constructor(http: HttpClient) {
    super(http, '/Appointment/DeleteAppointmentAndServices/');
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiAppointmentRestoreAppointmentAndServices extends ApiListDataService<any, any> {

  constructor(http: HttpClient) {
    super(http, '/Appointment/RestoreAppointmentAndServices/');
  }
}
