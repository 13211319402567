import {Injectable} from '@angular/core';


@Injectable()
export class LangStorageService {

  public static storageKey = 'lang';

  public getValue(): string {
    return localStorage[LangStorageService.storageKey];
  }

  public setValue(value: string) {
    localStorage[LangStorageService.storageKey] = value;
  }

  public removeValue() {
    localStorage.removeItem(LangStorageService.storageKey);
  }
}
