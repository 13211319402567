import {Injectable} from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class UserCredentialsStorageService {

  public static user = 'userCredential';

  public setValue(value: string) {
    localStorage[UserCredentialsStorageService.user] = value;
  }

  public removeValue() {
    localStorage.removeItem(UserCredentialsStorageService.user);
  }
}
