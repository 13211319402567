import * as fromActions from './actions';
import {UserModel} from '../../models';

export enum AuthenticatedState {
    Verifying = 1,
    Verified = 2
}

export interface AuthState {
    userInfo: UserModel;
    isAuthenticated: AuthenticatedState;
    isAuthenticating: boolean;
    errorText: any;
}

const initialState: AuthState = {
    userInfo: null,
    isAuthenticated: null,
    isAuthenticating: null,
    errorText: null
};

export function authReducer(state = initialState, action: fromActions.AuthActions): AuthState {
    console.log(action.type);
    switch (action.type) {
        case fromActions.AuthActionTypes.CHECK_AUTH:
            return {
                ...state,
                isAuthenticating: true,
                isAuthenticated: AuthenticatedState.Verifying,
            };
        case fromActions.AuthActionTypes.CHECK_AUTH_ERROR:
            return {
                ...state,
                isAuthenticating: null,
                isAuthenticated: null,
            };
        case fromActions.AuthActionTypes.LOGIN_USER:
            return {
                ...state,
                isAuthenticating: true,
                errorText: null,
            };
        case fromActions.AuthActionTypes.LOGIN_USER_SUCCESS:
            const payloadSuccess = (action as fromActions.LoginUserSuccess).payload;
            return {
                ...state,
                isAuthenticating: null,
                isAuthenticated: AuthenticatedState.Verified,
                userInfo: payloadSuccess,
                errorText: null,
            };
        case fromActions.AuthActionTypes.LOGIN_USER_ERROR:
            const payloadError = (action as fromActions.LoginUserError).payload;
            console.log(payloadError);
            return {
                ...state,
                isAuthenticating: null,
                isAuthenticated: null,
                userInfo: null,
                errorText: payloadError, // payloadError.error_description,
            };
        case fromActions.AuthActionTypes.LOGIN_USER_ERROR_CLEAR:
            return {
                ...state,
                isAuthenticating: null,
                errorText: null,
            };
        case fromActions.AuthActionTypes.LOGOUT_USER:
            return {
                ...state,
                isAuthenticated: null,
                userInfo: null,
                errorText: null,
            };
        default:
            return state;
    }
}
