import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiListDataService } from './api.list-data.service';
import { PackageModel } from '../../models';
import { MatchingPackageResponse } from 'core/models/matching-package.model';

@Injectable({
    providedIn: 'root',
})
export class ApiPackageService extends ApiListDataService<PackageModel, PackageModel> {
    constructor(http: HttpClient) {
        super(http, '/Package/');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiPackageWebregisterService extends ApiListDataService<PackageModel, PackageModel> {
    constructor(http: HttpClient) {
        super(http, '/Package/webregister');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiPackageLookupService extends ApiListDataService<PackageModel, PackageModel> {
    constructor(http: HttpClient) {
        super(http, '/Package/Lookup/');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiPackageCompatibilityByProjectsService extends ApiListDataService<
    MatchingPackageResponse,
    MatchingPackageResponse
> {
    constructor(http: HttpClient) {
        super(http, '/Package/Compatibility/ByProjects');
    }
}
@Injectable({
    providedIn: 'root',
})
export class ApiPackageCompatibilityExportByProjectsService extends ApiListDataService<
    MatchingPackageResponse,
    MatchingPackageResponse
> {
    constructor(http: HttpClient) {
        super(http, '/Package/Compatibility/ExportByProjects');
    }
}
@Injectable({
    providedIn: 'root',
})
export class ApiPackageCompatibilityService extends ApiListDataService<
    MatchingPackageResponse,
    MatchingPackageResponse
> {
    constructor(http: HttpClient) {
        super(http, '/Package/Compatibility');
    }
}
