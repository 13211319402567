import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'dc-snack-bar-component',
    template:
        '<div style="display:flex; align-items: center"><div [innerHTML]="data"></div><button style="background: transparent; border: none; color: #ff4081; padding: 10px; margin-left: 10px; cursor:pointer;" (click)="snackBarRef.dismiss()">OK</button></div>',
    standalone: true,
})
export class SnackBarComponent {
    constructor(
        public snackBarRef: MatSnackBarRef<SnackBarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: string,
    ) {}
}
