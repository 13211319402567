import {Injectable} from "@angular/core";

@Injectable()
export class DisableExpandingService {
  public disableExpanding(event: Event) {
    event.stopPropagation();
  }

  public scrollToView(id: number) {
    const el: HTMLElement = document.getElementById(`panel${id}`);
    if (el) {
      setTimeout(() => el.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'start',
      }), 400);
    }

  }
}
