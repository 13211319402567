import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'dc-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyPolicyComponent implements OnInit {
    constructor(private meta: Meta) {}

    ngOnInit(): void {
        this.meta.updateTag({ name: 'viewport', content: 'width=600' });
    }
}
