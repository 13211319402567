import { AbstractModel } from './abstract.model';

export interface UserByRoleAndOrgModel extends AbstractModel {
    totalCount: number;
    userName?: string;
    Organization?: string;
    data: UserByRoleAndOrgDataModel[];
}

export interface UserByRoleAndOrgDataModel extends AbstractModel {
    userName: string;
    email: string;
    phoneNumber: string;
    organization: UserByRoleAndOrgOrganizationsModel;
    role: number;
    lastName: string;
    firstName: string;
    middleName: string;
    id: number;
}

export interface UserByRoleAndOrgOrganizationsModel extends AbstractModel {
    code: string;
    name: string;
    id: number;
}
