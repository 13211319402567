import {Injectable} from '@angular/core';
import {ApiBaseService} from './api.base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiTokenService extends ApiBaseService {

  constructor(http: HttpClient) {
    super(http, '/token', null);
  }

  token = (email: string, password: string): Observable<any> => {
    return this.post('',
      new HttpParams({ fromObject: { grant_type: 'password', username: email, password } }),
      {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'None'
      });
  }
}
