import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiListDataService } from './api.list-data.service';
import { ProjectServiceModel } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class ApiProjectService extends ApiListDataService<
    ProjectServiceModel,
    ProjectServiceModel
> {
    constructor(http: HttpClient) {
        super(http, '/Project/');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiProjectPackageService extends ApiListDataService<
    ProjectServiceModel,
    ProjectServiceModel
> {
    constructor(http: HttpClient) {
        super(http, '/Project/Package');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiProjectPackagesService extends ApiListDataService<
    ProjectServiceModel,
    ProjectServiceModel
> {
    constructor(http: HttpClient) {
        super(http, '/Project/Packages');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiProjectItemsService extends ApiListDataService<
    ProjectServiceModel,
    ProjectServiceModel
> {
    constructor(http: HttpClient) {
        super(http, '/Project/ProjectItem');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiProjectWebregisterService extends ApiListDataService<
    ProjectServiceModel,
    ProjectServiceModel
> {
    constructor(http: HttpClient) {
        super(http, '/Project/webregister/');
    }
}
@Injectable({
    providedIn: 'root',
})
export class ApiProjectItemExportService extends ApiListDataService<
    ProjectServiceModel,
    ProjectServiceModel
> {
    constructor(http: HttpClient) {
        super(http, '/Project/ProjectItem/Export');
    }
}

@Injectable({
    providedIn: 'root',
})
export class ApiProjectLookupService extends ApiListDataService<any, any> {
    constructor(http: HttpClient) {
        super(http, '/Project/Lookup/');
    }
}
