import { AbstractModel } from './abstract.model';

export interface OrganizationModel extends AbstractModel {
    data: {
        edrpou?: string;
        chief?: string;
        country?: {
            code: string;
            name: string;
            id: number;
        };
        region?: {
            code: string;
            name: string;
            id: number;
        };
        district?: {
            code: string;
            name: string;
            id: number;
        };
        settlement?: {
            code: string;
            name: string;
            id: number;
        };
        settlementDistrict?: {
            code: string;
            name: string;
            id: number;
        };
        streetType?: {
            code: string;
            name: string;
            id: number;
        };
        street?: {
            code: string;
            name: string;
            id: number;
        };
        subrecipientOrganization?: {
            code: string;
            name: string;
            isSelected: boolean;
            id: number;
        };
        houseNum?: string;
        housing?: string;
        flatNum?: string;
        code?: string;
        name?: string;
        nameEn?: string;
        id?: number;
        roles?: Array<number>;
        ids?: object;
        latitude?: number;
        longitude?: number;
        data?: object[];
        totalCount?: number;
    };
    totalCount: number;
    orgRoles?: OrgRole[];
    name?: string;
    region?: any;
    district?: any;
    settlement?: any;
    settlementDistrict?: any;
}

export interface OrgRole {
    id: number;
    organizationId: number;
    role: number;
}
