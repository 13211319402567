import { AuditModel } from 'core/models/audit.model';

export interface ClientDocumentModel extends AuditModel {
    client: {
        code: string;
        name: string;
        id: number;
    };
    identDocType: 1;
    identDocSeries: string;
    identDocNumber: string;
    identDocIssue: string;
    identDocDate: string;
    id: number;
}
