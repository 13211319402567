import {Injectable} from "@angular/core";

@Injectable()
export class CompareService {
  public compare(a, b) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }
}
