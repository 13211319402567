export interface PackageServiceModel {
  package: {
    id: number
  };
  service: {
    id: number
  };
  count: number;
  period: number;
  previousService: {
    id: number
  };
  id: number;
}
