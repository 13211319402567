export interface AppointmentFilterModel {
  consultantList: [
    {
      lastName: string;
      firstName: string;
      middleName: string;
      id: number
    }
    ];
  organizationList: [
    {
      name: string;
      id: number
    }
    ];
  deliveryPointlist: [
    {
      latitude: number;
      longitude: number;
      code: string;
      name: string;
      id: number
    }
    ];
  serviceList: [
    {
      code: string;
      name: string;
      id: number
    }
    ];
}
