import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ErrorHandler } from './core/services';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { RequestInterceptor } from 'core/services/http.interceptor';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { SnackBarComponent } from './page/shared/components/snack-bar/snack-bar.component';

@NgModule({
    declarations: [AppComponent, PrivacyPolicyComponent],
    imports: [
        BrowserModule,
        CoreModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatSnackBarModule,
        LoadingBarHttpClientModule,
        SnackBarComponent,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [
        ErrorHandler,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
