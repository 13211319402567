import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiListDataService} from './api.list-data.service';
import {ClientCardModel} from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ApiClientCardService extends ApiListDataService<ClientCardModel, ClientCardModel> {

  constructor(http: HttpClient) {
    super(http, '/Client/Card/');
  }
}
