import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError as observableThrowError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {TokenStorageService} from '../services';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
    constructor(
        public tokenStorageService: TokenStorageService,
        public router: Router,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.has('Authorization')) {
            if (req.headers.get('Authorization').toLowerCase() === 'none') {
                req = req.clone({ headers: req.headers.delete('Authorization') });
            }
        } else {
            const token = this.tokenStorageService.getValue();
            if (token) {
                req = req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) });
            }
        }

        if (!req.headers.has('Content-Type')) {
            req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
        }

        req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
        req = req.clone({ headers: req.headers.set('Environment', 'web') });

        return next.handle(req)
            .pipe(
                catchError((err) => {
                    if (err.status === 403) {
                        // refresh
                        // this.tokenStorageService.removeValue();
                        this.router.navigate(['auth', 'login']);
                    }
                    return observableThrowError(err);
                })
            );
    }
}
