import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { empty as observableEmpty, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as fromActions from './actions';
import {
    ApiTokenService,
    ApiUserService,
    RoleStorageService,
    TokenStorageService,
    UserCredentialsStorageService,
} from '../../services';
import { Router } from '@angular/router';

@Injectable()
export class AuthEffects {
    constructor(
        public actions$: Actions,
        public apiTokenService: ApiTokenService,
        public apiUserService: ApiUserService,
        public tokenStorageService: TokenStorageService,
        public roleStorageService: RoleStorageService,
        public userCredentialsStorageService: UserCredentialsStorageService,
        private router: Router,
    ) {}

    @Effect()
    checkAuth$ = this.actions$.pipe(
        ofType(fromActions.AuthActionTypes.CHECK_AUTH),
        switchMap(() =>
            this.apiUserService.userInfo().pipe(
                map(user => {
                    return new fromActions.LoginUserSuccess(user);
                }),
                catchError(error => observableOf(new fromActions.CheckAuthError(error))),
            ),
        ),
    );

    @Effect()
    loginUser$ = this.actions$.pipe(
        ofType(fromActions.AuthActionTypes.LOGIN_USER),
        map((action: fromActions.LoginUser) => action.payload),
        switchMap(payload =>
            this.apiTokenService.token(payload.email, payload.password).pipe(
                map(token => {
                    const { access_token, user } = token;
                    const roles = token.user.Roles;
                    this.roleStorageService.setValue(roles);
                    this.tokenStorageService.setValue(access_token);
                    this.userCredentialsStorageService.setValue(
                        `${token.user.FirstName || 'G'} ${token.user.LastName || 'G'}`,
                    );
                    localStorage.setItem('org', token.user.Organization.Name);
                    localStorage.setItem('orgID', token.user.Organization.Id);
                    localStorage.setItem('userID', token.user.Id);
                    localStorage.setItem('specialRights', token.user.SpecialRights);

                    return new fromActions.LoginUserSuccess(user);
                }),
                catchError(error => {
                    console.log(error);

                    return observableOf(new fromActions.LoginUserError(error));
                }),
            ),
        ),
    );

    @Effect()
    logoutUser$ = this.actions$.pipe(
        ofType(fromActions.AuthActionTypes.LOGOUT_USER),
        switchMap(() => {
            this.roleStorageService.removeValue();
            this.tokenStorageService.removeValue();
            this.userCredentialsStorageService.removeValue();
            localStorage.removeItem('specialRights');
            localStorage.removeItem('orgID');
            localStorage.removeItem('org');
            localStorage.removeItem('userID');
            this.router.navigateByUrl('/auth/login');

            return observableEmpty();
        }),
    );
}
