import { AbstractModel } from './abstract.model';

export interface ServiceModel extends AbstractModel {
    data: {
        type: {
            code?: string;
            name?: string;
            id?: number;
        };
        cost: number;
        currency: {
            code: string;
            name: string;
            id: number;
        };
        duration: number;
        code: string;
        name: string;
        nameEn: string;
        fullName?: string;
        isMarkDelete?: boolean;
        id?: number;
    }[];
    totalCount: number;
}
