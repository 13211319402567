import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core';
import { ConsentComponent } from './auth/consent/consent.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    },
    {
        path: 'DataCollectionAndStorage',
        component: PrivacyPolicyComponent,
    },
    {
        path: '',
        loadChildren: () => import('./page/page.module').then(m => m.PageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'consent',
        component: ConsentComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
