import {AbstractDictModel} from './abstract-dict.model';

export interface PackageModel extends AbstractDictModel {
  totalCount: number;
  data: {
    code: string;
    name: string;
    nameEn: string;
    id: number;
  }[];
  id?: number;
}
