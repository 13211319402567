import {AbstractDictModel} from './abstract-dict.model';

export interface AppointmentPatchModel extends AbstractDictModel {
  value: {};
  path: string;
  op: string;
  from: string;
  lightClient?: boolean;
}

