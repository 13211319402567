import {Injectable} from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class FormatPayloadService {
  public formatObject(obj) {
    return function prune(current) {

      _.forOwn(current, (value, key) => {
        if (value === null || value === undefined || _.isNaN(value) ||
          (_.isString(value) && _.isEmpty(value)) || (Array.isArray(value) && value.length === 0)) {
          delete current[key];
        }
      });
      if (_.isArray(current)) _.pull(current, undefined);
      return current;

    }(_.cloneDeep(obj));
  }
}

export function formatDate(date) {
  let payloadDate: string;
  if (date) {
    const d = date.slice(0, 2);
    const mm = date.slice(2, 4);
    const y = date.slice(4, 8);
    payloadDate = `${y}.${mm}.${d}`;
  }
  return payloadDate;
}


export function formatPayloadRecursive(object) {
  if (!_.isObject(object)) {
    return;
  }
  _.keys(object).forEach(function (key) {
    var localObj = object[key];

    if (_.isObject(localObj)) {

      if (_.isEmpty(localObj)) {

        delete object[key];
        return;
      }

      // Is object, recursive call
      formatPayloadRecursive(localObj);

      if (_.isEmpty(localObj)) {

        delete object[key];
        return;
      }
    }
  });
  return object;
}
